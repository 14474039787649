import PageComponent from '../../common/component/page-component';

class Select extends PageComponent {

	prepare() {
		Select.openSelect = false;
		this.open = false;
		this.classList(this.element).add('mounted');
		this.valueEl = this.element.querySelector(this.classSelector('value'));
		this.optionsEl = this.element.querySelector(this.classSelector('options'));
		this.listeners.click = this.events.on(this.valueEl, 'click', this.toggleSelect.bind(this));
	}

	toggleSelect() {
		this.open = !this.open;
		this.classList(this.element).toggle('open');
		if (Select.openSelect && Select.openSelect !== this && Select.openSelect.open) {
			Select.openSelect.toggleSelect();
		}
		if (this.open) {
			Select.openSelect = this;
			this.optionsEl.querySelector('a').focus();
			this.listeners.escape = this.events.on(this.element, 'keydown', this.escape.bind(this));
			// need to delay, otherwise clickOutside is called immediately as the event bubbles up
			setTimeout(() => {this.listeners.clickOutside = this.events.on(document, 'body', 'click', this.clickOutside.bind(this));}, 100);
		} else {
			if (this.listeners) {
				this.listeners.escape.destroy();
				this.listeners.clickOutside.destroy();
			}
		}
	}

	escape(ev) {
		if (ev.keyCode === 27) {
			this.toggleSelect();
		}
	}

	clickOutside(ev) {
		if (!this.element.contains(ev.target) || this.element === ev.target) {
			this.toggleSelect();
		}
	}

}


export default Select;
