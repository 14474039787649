// adapted from https://remysharp.com/2010/07/21/throttling-function-calls
/*eslint-disable smells/no-this-assign, prefer-rest-params*/

export function debounce(fn, delay, scope) {
    let timer = null;
    return function () {
        const context = scope || this;
        const args = arguments;
        clearTimeout(timer);
        timer = setTimeout(() => {
            fn.apply(context, args);
        }, delay);
    };
}


export function throttle(fn, threshold, scope) {
    threshold = threshold || 250;
    let last;
    let deferTimer;
    return function () {
        const context = scope || this;
        const now = +new Date();
        const args = arguments;
        if (last && now < last + threshold) {
            // hold on to it
            clearTimeout(deferTimer);
            deferTimer = setTimeout(() => {
                last = now;
                fn.apply(context, args);
            }, threshold);
        } else {
            last = now;
            fn.apply(context, args);
        }
    };
}
