import Hammer from 'hammerjs';
import PageComponent from '../../common/component/page-component';
import animation from './animation';

class Collage extends PageComponent {

	prepare() {
		animation.prepare(this);
		this.addProjectListeners();
		this.isOpen = false; 				// main open/closed run/pause flag
		this.paused = false;				// stay open but run/pause flag
		this.baseSpeed = 0.5;				// a global speed factor (to tune speed of lateral scroll)
		this.mobileBreakpoint = 768;
		// toggler elements
		this.openerEl = this.element.querySelector(this.dataSelector('opener'));
		this.closerEl = this.element.querySelector(this.dataSelector('closeCollage'));
		this.mainEl = this.element.querySelector(this.dataSelector('main'));
		this.initCollageLeft();

		// mouse event listeners
		this.listeners.clickOpen = this.events.on(this.openerEl, 'click', this.onClickOpen.bind(this));
		this.listeners.clickClose = this.events.on(this.closerEl, 'click', this.onClickClose.bind(this));
		this.listeners.overClose = this.events.on(this.closerEl, 'mouseenter', this.offCollage.bind(this, true));
		this.listeners.offClose = this.events.on(this.closerEl, 'mouseleave', this.pause.bind(this, false));
		this.listeners.offCollage = this.events.on(this.element, 'mouseleave', this.offCollage.bind(this, true));
		this.listeners.overCollage = this.events.on(this.element, 'mouseenter', this.offCollage.bind(this, false));
		this.listeners.mousePos = this.events.on(this.root, 'mousemove', this.onMouseMove.bind(this));
		// other
		this.listeners.context = this.events.on(this.root, 'context:switchcomplete', this.onContextSwitch.bind(this));
		this.listeners.page = this.events.on(this.root, 'page:load', this.onLeavePage.bind(this));
		this.listeners.resize = this.events.on(window, 'window:resize', this.initCollageLeft.bind(this));
		this.classList(this.element).add('ready');

		this.touchCtrl = new Hammer(this.element, {domEvents: true});
		this.touchCtrl.on('pan', this.onPan.bind(this));
		this.listeners.touchStart = this.events.on(this.element, 'touchstart', this.onTouchStart.bind(this));
		this.listeners.touchEnd = this.events.on(this.element, 'touchend', this.onTouchEnd.bind(this));

	}

	startCollage() {
		animation.reinitialize();
		this.contexts.push('collage');
		this.isOpen = true;
		this.classList(this.element).add('open');
		requestAnimationFrame(this.update.bind(this));
		//setInterval(this.update.bind(this), 1000 / 25);
	}


	closeCollage() {
		this.isOpen = false;
		this.classList(this.element).remove('open');
		animation.prepareReset();
		this.putEverythingBackQuick();
		this.openerEl.style.visibility = 'visible';
	}

	/**
	 * Main update function for the animation
	 */
	update() {
		if (!this.isOpen) return;
		if (!this.paused) {
			animation.update();
		}
		requestAnimationFrame(this.update.bind(this));
	}

	putEverythingBackQuick() {
		if (this.isOpen) return;
		if (animation.isReset()) return;
		animation.resetUpdate();
		requestAnimationFrame(this.putEverythingBackQuick.bind(this));
	}


	/**
	 * Add listeners for project overlay elements
	 */
	addProjectListeners() {
		if (this.listenersAdded) return;
		for (const project of animation.projects) {
			this.listeners[`mouseEnter-${project.idx}`] = this.events.on(project.el, 'mouseenter', this.onHoverProject.bind(this, project));
			this.listeners[`mouseLeave-${project.idx}`] = this.events.on(project.el, 'mouseleave', this.onLeaveProject.bind(this, project));
			this.listeners[`click-${project.idx}`] = this.events.on(project.el, 'click', this.onClickProject.bind(this, project));
			this.listeners[`close-${project.idx}`] = this.events.on(project.closerEl, 'click', this.onCloseProject.bind(this, project));
		}
		this.listenersAdded = true;
	}

	/*
	 * The desktop collage reacts to mouse movement / touch position
	 * Mouse position determines an “xAccel“ factor: -0.5 on the far left and 0.5 on the far right of the window.
	 */
	onMouseMove(ev) {
		if (!this.isOpen) return;
		const factor = ((ev.pageX / window.innerWidth) - 0.5); // -0.5 to 0.5
		animation.setXAcceleration(factor * -this.baseSpeed);
	}

	onPan(ev) {
		if (!this.isOpen) return;
		if (ev.pointerType !== 'touch') return;
		animation.usingTouch = true;
		for (const project of animation.projects) {
			this.classList(project.el).remove('panOver');
			this.classList(project.labelEl).remove('panOver');
			if (ev.target === project.el) {
				this.classList(project.el).add('panOver');
				this.classList(project.labelEl).add('panOver');
			}
		}
		let factor = 0 - ((ev.deltaX / window.innerWidth) / 2);
		factor *= 1.2;
		factor = Math.min(0.5, factor);
		factor = Math.max(-0.5, factor);
		animation.setXAcceleration(factor * -this.baseSpeed);
	}

	onTouchStart(ev) {
		animation.brake(false);
	}

	onTouchEnd(ev) {
		animation.brake();
//		this.closeAllProjects();
	}

	// rollover a project
	onHoverProject(project, ev) {
		if (window.innerWidth < this.mobileBreakpoint) return;
		this.classList(project.el).add('show');
		animation.brake(true, true); // slow down !!!
	}

	// click (tap) a project
	onClickProject(project, ev) {
		if (ev.target === project.el) {
			ev.preventDefault();
			ev.stopPropagation();
		}
		this.closeAllProjects();
		// centre the project teaser on the screen
		const projectBounds = project.el.getBoundingClientRect();
		const offset = ((window.innerWidth / 2) - projectBounds.width / 2) - projectBounds.x;
		project.el.style.marginLeft = offset + 'px';
		this.classList(project.el).add('show');
		this.classList(this.element).add('showProject');
		this.element.focus();
		this.listeners.escape = this.events.on(this.element, 'keydown', this.onCloseProject.bind(this, project));
		this.listeners.tapOutside = this.events.on(window, 'click', this.onCloseProject.bind(this, project));
		this.pause();
	}

	onCloseProject(project, ev) {
		ev.preventDefault();
		ev.stopPropagation();
		project.el.style.marginLeft = 0;
		this.classList(project.el).remove('show');
		this.classList(this.element).remove('showProject');
		if (this.listeners) {
			this.listeners.escape.destroy();
			this.listeners.tapOutside.destroy();
		}
		animation.setXAcceleration(0);
		this.pause(false);
	}

	closeAllProjects() {
		for (const closeOther of animation.projects) {
			this.classList(closeOther.el).remove('panOver');
			this.classList(closeOther.el).remove('show');
		}
	}

	// rolloff a project
	onLeaveProject(project, ev) {
		if (window.innerWidth < this.mobileBreakpoint) return;
		this.classList(project.el).remove('show');
		animation.brake(false, true); // brakes off !!!
	}

	// open the entire collage
	onClickOpen() {
		if ('scrollBehavior' in document.documentElement.style) {
			window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
		} else {
			window.scrollTo(0, 0);
		}
		this.startCollage();
		setTimeout(() => {
			this.openerEl.style.visibility = 'hidden';
		}, 300);

	}

	// close the entire collage
	onClickClose() {
		this.contexts.pop();
	}

	onContextSwitch(event) {
		// close the collage if we leave the context
		const prevContext = event.detail.previousContext.getName();
		if (prevContext === 'collage') {
			this.closeCollage();
		}
	}

	onLeavePage(event) {
		// ensure that the collage is destroyed and hidden when we navigate away from the homepage
		// this is hacky
		const leavingHome = event.detail.page.url.split('/').length > 4;
		if (leavingHome) {
			this.element.parentNode.innerHTML = '';
		}
	}

	clear() {
		if (this.contexts.getCurrentContext().getName() === 'collage') {
			this.contexts.pop();
		}
		this.touchCtrl.off('pan', this.onPan.bind(this));
		this.touchCtrl.destroy();
	}

	pause(pause = true) {
		this.paused = pause;
	}

	offCollage(goingOff, ev) {
		if (goingOff) {
			if (ev.clientX <= 5 || Math.abs(window.innerWidth - ev.clientX) < 10) {
				// mouse going off at the sides, don’t stop scrolling
				return;
			}
			if (ev.target === this.closerEl) {
				return;
			}
			this.paused = true;
		} else {
			this.paused = false;
		}
	}

	initCollageLeft() {
		animation.offStage = Math.abs(Math.round(100 * (parseInt(getComputedStyle(this.mainEl).left, 10) / window.innerWidth))); // percentage off to the Left
	}


}

export default Collage;
