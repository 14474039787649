import PageComponent from '../../common/component/page-component';

/**
 * Manage the insertion and removal of media content from the media modal
 * In response to “media:show” events
 */
class MediaModal extends PageComponent {

	prepare() {
		this.currentContent = null;
		this.listeners.onShowMedia = this.events.on(this.root, 'media:show', this.onMediaShow.bind(this));
		this.listeners.context = this.events.on(this.root, 'context:switchcomplete', this.onContextSwitch.bind(this));
		this.modalContainer = this.element.querySelector(this.dataSelector('modalContent'));
	}

	onMediaShow(ev) {
		const data = ev.detail;
		// content is a MediaContent component
		const {content, id: newId} = data;
		// media is a Slideshow or VideoPlayer component
		const {media} = content;
		const isNewContent = !this.currentContent || this.currentContent.id !== newId;
		if (this.currentContent && isNewContent) {
			// send content element back “home” – out of modal
			this.currentContent.content.homeElement.appendChild(this.currentContent.content.getElement());
		}
		if (content.type === 'video') {
			media.play();
		}
		if (isNewContent) {
			// hope it’s ok to call load() repeatedly.
			media.load();
			// insert new content element into modal
			this.modalContainer.appendChild(content.getElement());
		}

		this.contexts.push('media');
		this.currentContent = data;
		if (content.type === 'slideshow') {
			// focus for keyboard navigation
			const nav = this.components.queryComponent(content.element, '.' + this.classListParser.buildBaseString('slideshowNavigation'));
			if (nav) {
				setTimeout(() => {
					nav.element.focus();
				}, 100);
			}
		}
	}

	onContextSwitch(ev) {
		const prevContext = ev.detail.previousContext.getName();
		if (prevContext === 'media' && this.currentContent) {
			if (this.currentContent.content.type === 'video') {
				this.currentContent.content.media.stop();
			}
		}
	}

}

export default MediaModal;
