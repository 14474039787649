import cookies from 'js-cookie';
import PageComponent from '../../common/component/page-component';

class NewsletterLink extends PageComponent {
	constructor({
		root,
		element,
		hiddenClass = 'hidden',
		expandedClass = 'expanded',
		cookieName = 'newsletterAnimation',
		initialAnimationClass = 'initialAnimation'

	}) {
		super({root: root, element: element});
		this.cookieName = cookieName;
		this.hiddenClass = hiddenClass;
		this.expandedClass = expandedClass;
		this.initialAnimationClass = initialAnimationClass;

		this.cookieDuration = 183;
		this.timeoutDuration = 5000;
	}

	prepare() {
		this.cookie = cookies.get(this.cookieName);

		if (!this.cookie) {
			this.listeners.click = this.events.on(this.element, 'click', this.onClick.bind(this));
		} else {
			this.classList(this.element).add(this.hiddenClass);
		}
	}


	onClick() {
		if (!this.cookie) {
			cookies.set(this.cookieName, 'true', {expires: this.cookieDuration});
			this.classList(this.element).add(this.hiddenClass);
		}
	}


	playAnimation() {
		requestAnimationFrame(() => {
			const initialTimeout = setTimeout(() => {
				this.classList(this.element).add(this.initialAnimationClass);
				clearTimeout(initialTimeout);

				this.onTransitionEnd(this.element).then(() => {
					this.classList(this.element).add(this.expandedClass);

					const expandTimeout = setTimeout(() => {
						this.classList(this.element).remove(this.expandedClass);
						clearTimeout(expandTimeout);

					}, this.timeoutDuration);
				});
			}, this.timeoutDuration);
		});
	}
}

export default NewsletterLink;
