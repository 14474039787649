import VideoPlayerDriver from '../media/video-player-driver';



/**
 * Youtube Player Driver (used internally by VideoPlayer Class)
 * Additional info on params available only for Youtube:
 * https://developers.google.com/youtube/player_parameters
 *
 * @class YoutubeVideoPlayerDriver
 * @extends {VideoPlayerDriver}
 */
class YoutubeVideoPlayerDriver extends VideoPlayerDriver {

	constructor({controller, element, url, params = {}} = {}) {
		super({controller: controller, element: element, url: url, params: params});
		Object.assign(this.params, {
			modestbranding: 1,
			enablejsapi: 1
		}, this.params);
		this.params = this.remapParams(this.params, {
			controls: (newParams, name, value) => {
				newParams.controls = (value ? 1 : 0);
				return newParams;
			},
			loop: (newParams, name, value) => {
				newParams.loop = (value ? 1 : 0);
				if (value && 'videoId' in params) {
					newParams.playlist = params.videoId;
				}
				return newParams;
			},
			start: (newParams, name, value) => {
				if (String(value) !== '0') {
					newParams.start = this.toSeconds(value);
				}
				return newParams;
			},
			end: (newParams, name, value) => {
				newParams.end = this.toSeconds(value);
				return newParams;
			}
		});
	}


	createInternalPlayer(api) {
		this.youtube = api;
		const videoId = ('videoId' in this.params ? this.params.videoId : null);
		if (!videoId) {
			throw Error('Youtube videoId param is required');
		}
		delete this.params.videoId;
		const width = ('width' in this.params ? this.params.width : '100%');
		const height = ('height' in this.params ? this.params.height : '100%');
		delete this.params.width;
		delete this.params.height;

		this.playerState = null;
		this.pendingActionTriggers = {};

		return new Promise((resolve) => {
			let params;
			if (videoId.startsWith('PL')) {
				params = {
					height: height,
					width: width,
					host: 'https://www.youtube-nocookie.com',
					events: {
						onReady: () => resolve(),
						onStateChange: this.onPlayerStateChange.bind(this),
						onPlaybackRateChange: this.onPlaybackRateChange.bind(this),
						onError: this.onError.bind(this)
					},
					playerVars: Object.assign({
						listType: 'playlist',
						list: videoId,
						origin: location.origin
					}, this.params)
				};
			} else {
				params = {
					videoId: videoId,
					height: height,
					width: width,
					host: 'https://www.youtube-nocookie.com',
					events: {
						onReady: () => resolve(),
						onStateChange: this.onPlayerStateChange.bind(this),
						onPlaybackRateChange: this.onPlaybackRateChange.bind(this),
						onError: this.onError.bind(this)
					},
					playerVars: Object.assign({
						origin: location.origin
					}, this.params)
				};
			}
			this.player = new this.youtube.Player(this.element, params);
		});
	}


	destroy() {
		if (this.player) {
			// removes the iframe from the dom
			this.player.destroy();
		}
	}


	load() {
		return super.load().then(() => {
			if (this.params.muted) {
				this.mute();
			}
			return this;
		});
	}



	onPlayerStateChange(event) {
		// -1 (unstarted)
		// 0 (ended)
		// 1 (playing)
		// 2 (paused)
		// 3 (buffering)
		// 5 (video cued).
		// When the player first loads a video, it will broadcast an unstarted (-1) event. When a video is cued and ready to play, the player will broadcast a video cued (5) event. In your code, you can specify the integer values or you can use one of the following namespaced variables:
		// YT.PlayerState.ENDED
		// YT.PlayerState.PLAYING
		// YT.PlayerState.PAUSED
		// YT.PlayerState.BUFFERING
		// YT.PlayerState.CUED
		const previousState = this.playerState;
		this.playerState = event.data;
		// console.log('state', this.playerState);
		if (this.pendingActionTriggers[event.data]) {
			const triggers = this.pendingActionTriggers[event.data];
			this.pendingActionTriggers[event.data] = [];
			for (const trigger of triggers) {
				trigger();
			}
		}

		const duration = this.player.getDuration();
		const time = this.player.getCurrentTime();
		const progress = (duration > 0 ? time / duration : 0);
		const eventData = {
			duration: duration,
			progress: progress,
			time: time
		};

		if (previousState === this.youtube.PlayerState.BUFFERING && this.playerState !== this.youtube.PlayerState.BUFFERING) {
			this.controller.triggerEvent(this.controller.eventNames.bufferend);
		}

		switch (this.playerState) {
			case this.youtube.PlayerState.BUFFERING:
				this.controller.triggerEvent(this.controller.eventNames.bufferstart);
				break;
			case this.youtube.PlayerState.ENDED:
				this.controller.triggerEvent(this.controller.eventNames.ended, eventData);
				break;
			case this.youtube.PlayerState.PAUSED:
				this.controller.triggerEvent(this.controller.eventNames.paused, eventData);
				break;
			case this.youtube.PlayerState.PLAYING:
				this.controller.triggerEvent(this.controller.eventNames.playing, eventData);
				break;

			default:
				// nop
		}
	}


	onPlaybackRateChange(event)	{
		this.controller.triggerEvent(this.controller.eventNames.speedchange, {speed: event.data});
	}


	onError(event) {
		const errorCode = event.data;
		let message;
		switch (errorCode) {
			case 2:
				message = 'The request contains an invalid parameter value';
				break;

			case 5:
				message = 'The video cannot be played in an HTML5 player, or another error related to the HTML5 player has occurred';
				break;

			case 100:
				message = 'The video requested was not found. This error occurs when a video has been removed (for any reason) or has been marked as private';
				break;

			case 101:
				// no break
				// eslint-disable-next-line no-fallthrough
			case 150:
				message = 'The owner of the requested video does not allow it to be played in embedded players';
				break;

			default:
				message = 'Undefined Video player error';
		}
		this.controller.triggerEvent(this.controller.eventNames.error, {
			message: message,
			errorCode: errorCode
		});
	}


	play() {
		return new Promise((resolve) => {
			if (this.playerState === this.youtube.PlayerState.PLAYING) {
				resolve();
			} else {
				this.addPendingTrigger(this.youtube.PlayerState.PLAYING, resolve);
				this.player.playVideo();
			}
		});
	}


	pause() {
		return new Promise((resolve) => {
			if (this.playerState === this.youtube.PlayerState.PAUSED || this.playerState === this.youtube.PlayerState.ENDED) {
				resolve();
			} else {
				this.addPendingTrigger(this.youtube.PlayerState.PAUSED, resolve);
				this.player.pauseVideo();
			}
		});
		// return this.player.pause();
	}


	mute() {
		this.player.mute();
		return Promise.resolve();
	}


	unMute() {
		this.player.unMute();
		return Promise.resolve();
	}



	getDuration() {
		return Promise.resolve(this.player.getDuration());
	}


	// Youtube supports only these values: 0.25, 0.5, 1, 1.5, and 2
	getSpeed() {
		return Promise.resolve(this.player.getPlaybackRate());
	}


	getTime() {
		return Promise.resolve(this.player.getCurrentTime());
	}


	/**
	 * Not available in this driver
	 * @returns {Promise} Resolved with 0
	 */
	getVideoHeight() {
		return Promise.resolve(0);
	}


	/**
	 * Not available in this driver
	 * @returns {Promise} Resolved with 0
	 */
	getVideoWidth() {
		return Promise.resolve(0);
	}


	/**
	 * NOTE: Most mobile devices don't support a volume level independent of the system volume. In these cases, this method always returns 1.
	 *
	 * @returns {Promise} Resolved with volume value in the range [0, 1]
	 */
	getVolume() {
		// Youtube volume is expressed in the range [0, 100], so it needs to be normalized
		return Promise.resolve(this.player.getVolume() / 100);
	}


	/**
	 * @returns {Promise} Resolved with a boolean
	 */
	isBuffering() {
		return Promise.resolve(this.player ? this.player.getPlayerState() === this.youtube.PlayerState.BUFFERING : false);
	}


	/**
	 * @returns {Promise} Resolved with a boolean
	 */
	isPaused() {
		return Promise.resolve(this.player ? this.player.getPlayerState() === this.youtube.PlayerState.PAUSED : true);
	}


	/**
	 * @returns {Promise} Resolved with a boolean
	 */
	isPlaying() {
		return Promise.resolve(this.player && this.player.getPlayerState ? this.player.getPlayerState() === this.youtube.PlayerState.PLAYING : false);
	}


	/**
	 * @returns {Promise} Resolved with a boolean
	 */
	isEnded() {
		return Promise.resolve(this.player ? this.player.getPlayerState() === this.youtube.PlayerState.ENDED : false);
	}


	/**
	 * @returns {Promise} Resolved with a boolean
	 */
	isMuted() {
		return Promise.resolve(this.player ? this.player.isMuted() : this.params.muted);
	}

	/**
	 * To know the speed values supported by the specific video, use the method  getAvailablePlaybackRates() from the internal player
	 *
	 * @param {Number} speed: youtube supports only one of this values: 0.25, 0.5, 1, 1.5, 2
	 * @returns {Promise} resolve immediately, but it is not guaranteed that the speed change is actually happened
	 */
	setSpeed(speed) {
		this.player.setPlaybackRate(speed);
		return Promise.resolve();
	}


	/**
	 * Youtube seekTo() second parameter not supported here, we set it to true.
	 * Use directly the youtube player method if needed
	 *
	 * @param {Number|String} time: new position. Accepted formats: 190 (seconds) | 3:10 | 3m10s | 3m | 10s
	 * @returns {Promise} It resolve immediately without waiting the seek to be completed, returning the new time
	 */
	setTime(time) {
		this.player.seekTo(this.toSeconds(time), true);
		return Promise.resolve(this.player.getCurrentTime());
	}


	/**
	 * @param {Number} volume: value in the range [0, 1]
	 * @returns {Promise} resolves immediately, returning the new volume value
	 */
	setVolume(volume) {
		// Youtube volume is expressed in the range [0, 100], so it needs to be normalized
		this.player.setVolume(Math.round(volume * 100));
		return Promise.resolve(this.getVolume());
	}



	addPendingTrigger(status, trigger) {
		if (!(status in this.pendingActionTriggers)) {
			this.pendingActionTriggers[status] = [];
		}
		this.pendingActionTriggers[status].push(trigger);
	}

}


export default YoutubeVideoPlayerDriver;
