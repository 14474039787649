// import Accordion from './accordion';
import Calendar from './calendar';
// import CalendarEvents from './calendar-events';
import Collapsable from './collapsable';
// import ContentSlider from './content-slider';
// import ContentSliderNavigation from './content-slider-navigation';
import ContextToggler from './context-toggler';
// import CustomImageMap from './custom-image-map';
// import CustomSelect from './custom-select';
import ContentSliderGsap from './content-slider-gsap';
import CollapsingHeader from './collapsing-header';
import GestureAwareLink from './gesture-aware-link';
// import PaginationNavigation from './pagination-navigation';
// import Tabs from './tabs';
import Toggler from './toggler';
// import TogglerController from './toggler-controller';


export default (di) => {
	di
		// .setType({type: Accordion, name: 'Accordion', parent: 'PageComponent'})
		.setType({type: Calendar, name: 'Calendar', parent: 'PageComponent'})
		// .setType({type: CalendarEvents, name: 'CalendarEvents', parent: 'PageComponent'})
		.setType({type: Collapsable, name: 'Collapsable', parent: 'PageComponent'})
		// .setType({type: ContentSlider, name: 'ContentSlider', parent: 'PageComponent'})
		// .setType({type: ContentSliderNavigation, name: 'ContentSliderNavigation', parent: 'PageComponent'})
		.setType({type: ContextToggler, name: 'ContextToggler', parent: 'PageComponent'})
		// .setType({type: CustomImageMap, name: 'CustomImageMap', parent: 'PageComponent'})
		// .setType({type: CustomSelect, name: 'CustomSelect', parent: 'PageComponent'})
		.setType({type: ContentSliderGsap, name: 'ContentSliderGsap', parent: 'PageComponent'})
		.setType({type: CollapsingHeader, name: 'CollapsingHeader', parent: 'PageComponent'})
		.setType({type: GestureAwareLink, name: 'GestureAwareLink', parent: 'PageComponent'})
		// .setType({type: PaginationNavigation, name: 'PaginationNavigation', parent: 'PageComponent'})
		// .setType({type: Tabs, name: 'Tabs', parent: 'PageComponent'})
		.setType({type: Toggler, name: 'Toggler', parent: 'PageComponent'})
		// .setType({type: TogglerController, name: 'TogglerController', parent: 'PageComponent'})
	;
};
