import cookies from 'js-cookie';
import App from '../../common/app/app';

class MainApp extends App {

	injectCurrentLinkManager(currentLinkManager) {
		this.currentLinkManager = currentLinkManager;
	}


	injectMatomoTracker(tracker) {
		this.matomoTracker = tracker;
	}

	injectCustomEventTracker(tracker) {
		this.customEventTracker = tracker;
		this.customEventTracker.init();
	}

	injectHashScroller(hashScroller) {
		this.hashScroller = hashScroller;
	}


	injectPageSlots(pageSlots) {
		this.pageSlots = pageSlots;
	}


	injectPages(pages) {
		this.pages = pages;
	}


	init() {
		this.contexts.setDefaultContext('default', false);
		return super.init();
		// .then(() => {
		// 	this.loader = this.getComponent('appLoader');
		// });
	}


	execute() {
		this.pages.initCurrent()
			// .then(() => {
			// 	console.log('before loader animation');
			// 	return this.loader.playAnimation();
			// })
			.then(() => {
				this.contexts.getContext('default').activate();
				// this.currentLinkManager.update();
				const NewsletterLinkComponent = this.getComponent('NewsletterLink');
				const cookieName = NewsletterLinkComponent.cookieName;
				const newsletterLinkCookie = cookies.get(cookieName);

				if (!newsletterLinkCookie) {
					NewsletterLinkComponent.playAnimation();
				}


				const hash = location.hash;
				if (hash.length) {
					this.events.trigger(document.body, 'history:hashchange', {hash: hash.substr(1)});
				}
			})
		;
	}



}

export default MainApp;
