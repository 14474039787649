import httpRequest from 'superagent';
import PageComponent from '../../common/component/page-component';

class ContactForm extends PageComponent {

	constructor({root, element, busyClass = 'busy', messageAttribute = 'message', fieldsAttribute = 'fields'}) {
		super({root: root, element: element});
		this.busyClass = busyClass;
		this.messageAttribute = messageAttribute;
		this.fieldsAttribute = fieldsAttribute;
		this.busy = false;
	}


	prepare() {
		this.message = this.element.querySelector(this.dataSelector(this.messageAttribute));
		this.fields = this.element.querySelector(this.dataSelector(this.fieldsAttribute));
		this.numberAccompanyingField = this.element.querySelector(`${this.dataSelector('signupNumber')} input`);
		this.accompanyingNamesField = this.element.querySelector(this.dataSelector('signupAdditionalNames'));
		this.accompanyingEmailsField = this.element.querySelector(this.dataSelector('signupAdditionalEmails'));

		this.listeners.changeNumber = this.events.on(this.numberAccompanyingField, 'change', this.onChangeNumberAccompanying.bind(this));
		this.listeners.submit = this.events.on(this.element, 'submit', this.onSubmit.bind(this));

		this.onChangeNumberAccompanying();
	}


	onSubmit(event) {
		event.preventDefault();
		if (!this.busy) {
			this.toggleBusy();
			const data = this.getFormData();
			const json = JSON.stringify(data);
			httpRequest
				.post(this.element.action)
				.field('data', json)
				.set('X-Requested-With', 'XMLHttpRequest')
				.set('Accept', 'application/json')
				.end((error, response) => {
					this.toggleBusy();
					if (error || !response.ok) {
						//console.error('response error', error, response);
						this.classList(this.element).add('error');
					} else {
						const body = response.body;
						this.message.textContent = body.message;
						this.classList(this.element).add('success');
					}
				})
			;
		}
	}


	getFormData() {
		const data = {};
		// Loop through each field in the form
		for (const field of this.element.elements) {
			// Don't serialize fields without a name, submits, buttons, file and reset inputs, and disabled fields
			if (!field.name || field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') {
				continue;
			}
			if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
				data[field.name] = field.value;
			}
		}
		return data;
	}


	toggleBusy() {
		this.busy = !this.busy;
		this.classList().toggle(this.busyClass, this.busy);
	}

	// show/hide fields depending on whether the user has specified a number of accompanying visitors.
	onChangeNumberAccompanying() {
		const numberAccompanying = parseInt(this.numberAccompanyingField.value, 10);
		if (numberAccompanying > 0) {
			this.accompanyingEmailsField.style.display = 'block';
			this.accompanyingNamesField.style.display = 'block';
			this.accompanyingNamesField.querySelector('textarea').setAttribute('required', true);
		} else {
			this.accompanyingEmailsField.style.display = 'none';
			this.accompanyingNamesField.style.display = 'none';
			this.accompanyingNamesField.querySelector('textarea').removeAttribute('required');
		}
	}

}

export default ContactForm;
