import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';
import PageComponent from '../../common/component/page-component';
import {debounce} from '../../common/utils/call-rate';

// see https://masonry.desandro.com/

class MasonryGrid extends PageComponent {

	constructor({root, element, masonryOptions = {}}) {
		super({root: root, element: element});
		this.customOptions = masonryOptions;
	}

	prepare() {
		const cellSel = this.classSelector('masonryCell');
		this.options = Object.assign({
			itemSelector: cellSel,
			columnWidth: cellSel,
			percentPosition: true,
			gutter: 15,
			resize: false,
			resizeLimit: 768, // must match relevant breakpoint in CSS
			transitionDuration: 150
		}, this.customOptions);
		this.layout();
		const loadListener = imagesLoaded(this.element);
		this.debouncedLayout = debounce(this.layout, 100, this);
		loadListener.on('progress', () => {
			this.debouncedLayout();
		});
		this.listeners.resize = this.events.on(window, 'window:resize', this.debouncedLayout);
	}


	layout() {
		if (window.innerWidth > this.options.resizeLimit) {
			if (!this.masonry) {
				this.masonry = new Masonry(this.element, this.options);
			}
			this.classList(this.element).add('masonryOn');
			this.masonry.layout();
		} else {
			if (this.masonry) {
				this.classList(this.element).remove('masonryOn');
				this.masonry.destroy();
				this.masonry = null;
			}
		}
	}


	clear() {
		if (this.masonry) {
			this.masonry.destroy();
		}
		super.clear();
	}
}

export default MasonryGrid;
