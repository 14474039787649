import PageComponent from '../../common/component/page-component';

/**
 * An element which will trigger the opening of a MediaContent item (a wrapper around a slideshow or video) in a modal context
 * MediaContent item is identified with an id (forMedia)
 */
class MediaToggler extends PageComponent {

	constructor({
								root,
								element,
							}) {
		super({root: root, element: element});
		this.media = null;
	}

	prepare() {
		this.forMediaId = this.dataAttr().get('forMedia');
		const selector = this.dataSelector('id', this.forMediaId);
		this.mediaContent = this.components.queryComponent(this.root, selector);

		if (this.mediaContent) {
			this.mediaContent.setSurviveDetached(true);
			this.media = this.mediaContent.media;
			this.media.setSurviveDetached(true);
			this.contentElement = this.mediaContent.getElement();
			this.listeners.click = this.events.on(this.element, 'click', this.onClick.bind(this));
		}
	}

	onClick(ev, target) {
		this.events.trigger(this.root, 'media:show', {
			content: this.mediaContent,
			id: this.forMediaId
		});
	}



}

export default MediaToggler;
